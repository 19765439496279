<script>
export let size="24";
export let fill="none";
export let color="var(--color)";
export let light=false;
export let width="2px";
export let linecap="round";
export let linejoin="round";
export let name="";
export let inactive=false;
export let light_color="var(--color-text-inverted)";
export let shadow=false;
export let tooltip=false;
export let tip_position="bottom";
</script>

<style>
  .inactive {
    stroke: var(--color-inactive);
  }

  .shadow {
    -webkit-filter: drop-shadow(5px 3px 3px var(--color-overlay-shadow));
    filter: drop-shadow(5px 3px 3px var(--color-overlay-shadow));
  }

  span {
    max-width: min-content;
  }

  span.tipped {
    position: relative;
    padding: 0px !important;
    display: block;
    margin: 0px !important;
  }
</style>

<span class:tipped={ tooltip != false }>
  {#if tooltip}
    <tooltip class={ tip_position }>{ tooltip }</tooltip>
  {/if}
  <svg class="icon-{name}" class:inactive={inactive} class:shadow={ shadow }
    width="{size}"
    height="{size}"
    fill="{fill}"
    stroke="{light ? light_color : color}"
    stroke-width="{width}"
    stroke-linecap="{linecap}"
    stroke-linejoin="{linejoin}"
  >
    <use href="/icon-sprites.svg#{name}"/>
  </svg>
</span>
