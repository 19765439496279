<script>
  import Icon from './Icon.svelte';
  import { darkmode_default } from "$/client/config.js";
  import { darkmode } from "$/client/stores.js";
  import {onMount} from 'svelte';

  export let theme = "light";
  export let light_icon_color="var(--color)";
  export let dark_icon_color="var(--color)"

  const set_theme = () => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);

    darkmode.update(() => {
      return { theme };
    });
  }

  const toggle = () => {
    theme = theme == 'light' ? 'dark' : 'light';
    set_theme();
  }

  onMount(() => {
    theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : darkmode_default;
    set_theme()
  });
</script>

{#if theme == 'dark'}
  <span style="cursor: pointer;" on:click={ () => toggle() }>
    <Icon name="sun" color={ dark_icon_color } tip_position="bottom-left" tooltip="Light mode." size="32" />
  </span>
{:else}
  <span style="cursor: pointer;" on:click={ () => toggle() }>
    <Icon name="moon" color={ light_icon_color } size="32" tip_position="bottom-left" tooltip="Dark mode."/>
  </span>
{/if}
