/*
  The main Svelte stores used in the application.  The main one is the `user`, which
  contains a store for the current user. Svelte stores are a useful way to maintain
  information that's used across pages.  I've left in a few that I used for caching
  in my courses at https://learnjsthehardway.com so you can see how to use a store
  for caching.
 */
import { writable } from 'svelte/store';

/*
  Look in api/login.js and client/pages/Login.svelte for where this is initially setup.
 */
export const user = writable({
  authenticated: undefined, // yes, this is on purpose
});

/*
  I used this for some basic caching of course items, since constantly loading
  them on every click was pointless.  The course material doesn't change much,
  so caching it makes sense.  Why didn't I use Service Workers instead?  Service
  Workers are very unreliable when it comes to reloading.  They require a lot of
  extra gear just to make simple refreshing a page of content work.  If what you
  cache persists across refresh the go ahead and use Service Worker caching.  If
  not then just do it this way.
 */
export const cache = writable({
  courses: {},
  modules: {},
  lessons: {},
  recents: {}
});

/*
  Clears out the cache.  Again, mostly a demo.
 */
export const cache_reset = () => {
  cache.update(() => ({
    courses: {},
    modules: {},
    lessons: {},
    recents: {}
  }));
}

/*
  Use for video configuration information that's shared between
  video pages.
 */
export const video_config = writable({video_ready: false});

/*
  The user's darkmode setting.
 */
export const darkmode = writable({theme: "light"});
